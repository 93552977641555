<template>
  <div class="pane-container">
    <div class="pane-name">
      {{ title }}
    </div>
    <div class="table-header">
      <span class="col-1">类型</span>
      <span class="col-2">区域</span>
      <span class="col-3">内容</span>
      <span class="col-4">时间</span>
    </div>
    <div class="table-body">
      <div v-for="item in data" :key="item.id" class="table-li">
        <span class="col-1">
          {{ item.demandType == 1 ? "需求" : "供应" }}
        </span>

        <span class="col-2"> {{ item.demandAddress }} </span>
        <span class="col-3"> {{ item.demandName }} </span>
        <span class="col-4">
          {{ item.createTime ? item.createTime.substring(0, 10) : "--" }}
        </span>
      </div>
    </div>
    <div class="page-span">
      <Page
        size="small"
        :current="page.pageNo"
        :page-size="page.pageSize"
        :total="page.total"
      ></Page>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "供需列表",
    },
    searchData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0,
      },
    };
  },
  watch: {
    searchData: {
      handler() {
        this.getData();
      },
      immediate: true,
    },
  },
  methods: {
    //   查询数据
    getData() {
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.data = res.list;
        this.page.total = +res.total;
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>