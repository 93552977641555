<template>
  <div class="page-container">
    <div class="row-one">
      <div class="pane-span">
        <trade-pane :searchData="{ demandType: '0' }" title="最新供应">
        </trade-pane>
      </div>
      <div class="pane-span">
        <trade-pane :searchData="{ demandType: '1' }" title="最新需求">
        </trade-pane>
      </div>
      <div class="pane-span">
        <trade-pane :title="areaPane.title"> </trade-pane>
      </div>
    </div>
    <div class="table">
      <h-table
        :options="options"
        border
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        @page-change="pageChange"
      ></h-table>
    </div>
  </div>
</template>
<script>
import TradePane from "./tradePane";

export default {
  components: {
    TradePane,
  },
  data() {
    return {
      //   区域板块名称
      areaPane: {
        title: "万石镇供需",
        searchData: {},
      },
      table: {
        data: [],
        columns: [
          {
            title: "供需类型",
            render: (h, { row }) => {
              return <span>{row.demandType == 1 ? "需求" : "供应"}</span>;
            },
          },
          {
            title: "地址",
            key: "demandAddress",
          },
          {
            title: "内容",
            key: "demandName",
          },
          {
            title: "联系人",
            key: "telName",
          },
          {
            title: "联系电话",
            key: "telPhone",
          },
          {
            title: "时间",
            key: "createTime",
          },
        ],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0,
      },
    };
  },
  computed: {
    options() {
      return {
        page: this.page,
      };
    },
  },
  methods: {
    //   查询数据
    getData() {
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.table.data = res.list;
        this.page.total = +res.total;
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>